<template>
  <div>
    <div class="content bg-gray-lighter ng-scope">
      <!--<h1 style="color: red;">FUNZIONALITÀ IN FASE DI TEST</h1>-->
      <div class="row items-push">
        <div class="col-sm-10">
          <h1 class="page-heading">
            Schedario Periti Attivi <small>Lista periti attivi.</small>
          </h1>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- Dynamic Table Full -->
      <div class="block">
        <div class="block-header">
          <div class="block-content">
            <div class="row row-custom-periti-attivi">
              <div class="col-sm-12 col-md-8 container-input-search-schedario">
                <div >
                <label class="">Cerca per codice perito</label>
                <br />
                <input
                  type="text"
                  v-model="search_perito"
                  placeholder="...codice perito"
                />
              </div>
              <div class="col-sm-12 col-md-8">
                <label class="">Cerca per cognome</label>
                <br />
                <input
                  type="text"
                  v-model="search_cognome"
                  placeholder="...cognome"
                />
              </div>
            </div>
              <div class="con-btn-periti-attivi">
                <button class="btn-periti-attivi" @click="exportToExcel">
                  download lista periti attivi
                </button>
              </div>
              <div class="con-btn-periti-attivi">
                <button
                  class="btn-periti-attivi"
                  @click="createExcelFile(null)"
                >
                  download periti attivi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                    <date-range-picker
                                        style="width: 100%;"
                                        ref="picker"
                                        :locale-data="localeRange"
                                        :autoApply="true"
                                        :ranges="false"
                                        v-model="dateRange"
                                        @select="dayClicked"
                                    >
                                        <template v-slot:input="picker" style="width:100%;">
                                            <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                            <span v-else>Seleziona Range di ricerca</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <button @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
      <div class="block">
        <div class="block-content block-content-full">
          <h3 v-if="periti_attivi.length == 0">Nessuna Specifica</h3>
          <div
            v-if="periti_attivi.length > 0"
            class="row"
            style="margin-bottom: 30px"
          >
            <div class="col-sm-12 col-md-6">
              <!--<div class="dataTables_length" id="DataTables_Table_0_length">
                <label>
                  <select
                    v-model="search.per_page"
                    @change="perPage"
                    name="DataTables_Table_0_length"
                    class="form-control form-control-sm"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </label>
              </div>-->
            </div>
          </div>
          <table
            v-if="periti_attivi.length > 0"
            class="table table-bordered table-striped js-dataTable-full dataTable"
          >
          <thead>
              <tr>
                <th class="d-none d-sm-table-cell pointer-table" @click="sortBy('codice_perito')">
                  COD. PERITO
                  <span v-if="currentSort === 'codice_perito'">
                    <i v-if="currentSortDir === 'asc'" class="fa fa-arrow-up"></i>
                    <i v-if="currentSortDir === 'desc'" class="fa fa-arrow-down"></i>
                  </span>
                </th>
                <th class="d-none d-sm-table-cell pointer-table" @click="sortBy('name')">
                  NOME
                  <span v-if="currentSort === 'name'">
                    <i v-if="currentSortDir === 'asc'" class="fa fa-arrow-up"></i>
                    <i v-if="currentSortDir === 'desc'" class="fa fa-arrow-down"></i>
                  </span>
                </th>
                <th class="d-none d-sm-table-cell pointer-table" @click="sortBy('surname')">
                  COGNOME
                  <span v-if="currentSort === 'surname'">
                    <i v-if="currentSortDir === 'asc'" class="fa fa-arrow-up"></i>
                    <i v-if="currentSortDir === 'desc'" class="fa fa-arrow-down"></i>
                  </span>
                </th>
                <th class="d-none d-sm-table-cell pointer-table">AZIONI</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(perito, index) in filtered_periti" :key="index">
                <td class="font-size-sm">
                  {{ perito.codice_perito }}
                </td>
                <td class="font-size-sm">
                  {{ perito.name }}
                </td>
                <td class="d-none d-sm-table-cell font-size-sm">
                  {{ perito.surname }}
                </td>

                <td class="d-none d-sm-table-cell font-size-sm">
                  <div class="btn-group">
                    <button
                      @click="infoCertificato(perito)"
                      type="button"
                      class="btn btn-sm btn-light"
                      data-toggle="tooltip"
                      title="visualizza dettaglio"
                    >
                      <i class="fa fa-fw fa-eye"></i>
                    </button>
                    <button
                      @click="createExcelFile(perito)"
                      type="button"
                      class="btn btn-sm btn-light"
                      data-toggle="tooltip"
                      title="scarica dettaglio utente"
                    >
                      <i class="fa fa-cloud-download-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
            <paginate
              v-model="search.page"
              :page-count="data.last_page"
              :click-handler="paginateClick"
              :prev-text="prev"
              :next-text="next"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'prev'"
              :next-class="'next'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>

    <ModalPeritiAttivi
      ref="modal_specifica"
      :perito_selezionato="perito_selezionato"
      :isModalVisible="isModalInfoVisible"
      @closeModalCallback="close_modal_callback"
      @addData="addData"
    />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import ModalPeritiAttivi from "../../../components/Specifiche/ModalPeritiAttivi";
//import DateRangePicker from 'vue2-daterange-picker'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
//import UtilityRepository from "../../../services/apis/utility"
import userApi from "../../../services/apis/user";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs";
import intestazioneImage from "@/assets/images/ara_intestazione.png";
//import Utility from '../../../services/utility'
//import Config from '../../../services/config'
//import ThTable from '../../../components/ThTable'
//import SelettoreRicerca from '../../../components/SelettoreRicerca'

export default {
  components: {
    //ThTable,
    Paginate,
    ModalPeritiAttivi,
    //SelettoreRicerca,
    //DateRangePicker
  },
  data() {
    return {

      abbreviazioniMappa: {
        Allievo: "ALL",
        "Aspirante rilevatore": "ASP RIL",
        Assistente: "ASS",
        "Responsabile di squadra": "RS",
        "Responsabile di Squadra con funzione ispettiva": "RSFI",
        Rilevatore: "RIL",
        "Rilevatore speciale": "RIL SPEC",
      },
      dateRange: {},
      ispettore_id: 0,
      isModalInfoVisible: false,
      data: {
        last_page: 1,
      },
      search_perito: "",
      search_cognome:"",
      timer: null,
      periti_attivi: [],
      filtered_periti: [],
      currentSort: 'codice_perito', // Colonna di default per l'ordinamento
      currentSortDir: 'asc', // Direzione di default per l'ordinamento
      perito_selected: null,
      perito_selezionato: null,
      search: {
        perito_id: null,
        per_page: 10,
        page: 1,
        orderBy: "data_specifica:desc",
      },
      tableHeaders: [
        "codice_perito",
        "name",
        "surname",
        "pec",
        "tel",
        "iscrizione_albo",
        "cassa_previdenza",
        "polizza_rcd",
        "anno_inserimento",
        "anno_nascita",
        "products",
        "titolo_name",
        "titolo_studio",
        "regime",
        "disponibilita",
        "codice_fiscale",
        "partita_iva",
        "indirizzo",
        "cap",
        "provincia",
        "comune",
        "nome_banca",
        "iban",
        "grade_name",
        "onorario",
      ],
      fatturatoHeaders: [
        "anno",
        "giornate_di_lavoro",
        "complessivo_pagato",
        "note",
      ],
      inserimento: {
        1950: "#fff",
        1990: "#FF3333",
        1991: "#FF8D33",
        1992: "#FFC733",
        1993: "#FFF933",
        1994: "#D4FF33",
        1995: "#9EFF33",
        1996: "#5AFF33",
        1997: "#33FF57",
        1998: "#33FF8D",
        1999: "#33FFC7",
        2000: "#33FFF9",
        2001: "#33D4FF",
        2002: "#339EFF",
        2003: "#335AFF",
        2004: "#5733FF",
        2005: "#8D33FF",
        2006: "#C733FF",
        2007: "#F933FF",
        2008: "#FF33D4",
        2009: "#FF339E",
        2010: "#FF335A",
        2011: "#FF5773",
        2012: "#FF8D99",
        2013: "#FFC7BF",
        2014: "#FFF9E1",
        2015: "#D4FFE1",
        2016: "#9EFFD4",
        2017: "#5AFFD4",
        2018: "#33FFEB",
        2019: "#33E1FF",
        2020: "#33BFFF",
        2021: "#338AFF",
        2022: "#335AFF",
        2023: "#5733FF",
        2024: "#8D33FF",
        2025: "#C733FF",
        2026: "#F933FF",
        2027: "#FF33D4",
        2028: "#FF339E",
        2029: "#FF335A",
        2030: "#FF5733",
        2031: "#FF8D33",
        2032: "#FFC733",
        2033: "#FFF933",
        2034: "#D4FF33",
        2035: "#9EFF33",
        2036: "#5AFF33",
        2037: "#33FF57",
        2038: "#33FF8D",
        2039: "#33FFC7",
        2040: "#33FFF9",
        2041: "#33D4FF",
        2042: "#339EFF",
        2043: "#335AFF",
        2044: "#5733FF",
        2045: "#8D33FF",
        2046: "#C733FF",
        2047: "#F933FF",
        2048: "#FF33D4",
        2049: "#FF339E",
        2050: "#FF335A",
      },
      intestazione: require("@/assets/images/ara_intestazione.png"),
      

    };
  },
  watch: {
    search_perito(item) {
      if (item && item !== "") {
        // Utilizziamo la funzione debounced per il filtro
        this.debounceFunc();
      }
    },

    search_cognome(item) {
      if (item && item !== "") {
        // Utilizziamo la funzione debounced per il filtro
        this.debounceCognomeFunc();
      }
    },
  },
  methods: {
    sortBy(column) {
    if (this.currentSort === column) {
      // Se la colonna corrente è la stessa, inverti la direzione
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
    } else {
      // Altrimenti, imposta la nuova colonna e ordina in ascendente
      this.currentSort = column;
      this.currentSortDir = 'asc';
    }

    let mySort = this.periti_attivi.sort((a, b) => {
      let modifier = 1;
      if (this.currentSortDir === 'desc') modifier = -1;
      if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
      if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      return 0;
    });


    this.filtered_periti = mySort;


  },
    addData(data) {
      this.perito_selezionato = { ...this.perito_selezionato, data };
      console.log(this.perito_selezionato, "aggiunta");
    },
    // Funzione per ottenere la frase dall'abbreviazione
    getFraseDaAbbreviazione(abbreviazione) {
      return (
        this.abbreviazioniMappa[abbreviazione] ||
        "Nessuna corrispondenza trovata"
      );
    },

    convertImageToBase64(imageUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
        };
        img.onerror = (err) => reject(err);
        img.src = imageUrl;
      });
    },
    async createExcelFile(perito) {
      console.log(perito);

      // Create a new workbook
      const wb = new ExcelJS.Workbook();

      // Data and initial formatting
      const data = [
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, "codice H@ilne", null],
        [null, null, null, null, null, null, null, null],
        ["cognome", null, null, "titolo", "nome", null, null, null],
        [null, null, null, null, null, null, null, null],
        ["indirizzo abitazione", null, null, null, null, null, "m@il", null],
        [null, null, null, null, null, null, null, null],
        [
          "codice fiscale",
          null,
          null,
          "partita IVA",
          null,
          null,
          "abitazione",
          "cellulare",
        ],
        [null, null, null, null, null, null, null, null],
        [
          "anno di nascita",
          "iscrizione",
          "professione",
          null,
          "n° iscr. e prov.",
          null,
          "albo professionale",
          null,
        ],
        [null, null, null, null, null, null, null, null],
        ["ALL", "ASS.", "ASP. RIL.", null, "RIL.", null, "RIL. SPEC.", null],
        ["anno", "anno", "anno", null, "anno", null, "anno", null],
        ["R.S.", "R.S.F.I.", null, null, null, null, null, null],
        ["anno", "anno", "prodotti conosciuti", null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [
          "PARTECIPAZIONE AD INCONTRI - CORSI - RIUNIONI",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        [
          "DATA",
          "LUOGO",
          "TIPO RIUNIONE E PRODOTTO",
          null,
          null,
          null,
          null,
          "NOTE",
        ],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [
          "ANNO",
          "GIORNATE DI LAVORO",
          "COMPLESSIVO PAGATO",
          null,
          "NOTE",
          null,
          null,
          null,
        ],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null],
      ];

      // Column formatting
      const columnWidths = {
        A: 10,
        B: 10,
        C: 10,
        D: 10,
        E: 10,
        F: 10,
        G: 10,
        H: 10,
      };

      // Sort users and create a sheet for each user
      this.periti_attivi.sort((a, b) =>
        a.surname > b.surname ? 1 : b.surname > a.surname ? -1 : 0
      );
      for (let i = 0; i < this.periti_attivi.length; i++) {
        if (perito) {
          if (perito.id != this.periti_attivi[i].id) {
            continue;
          }
        }

        // Retrieve user data
        const utente = this.periti_attivi[i];
        const cognome = utente.surname.replace(/[^a-zA-Z0-9\s]/g, "");
        const name = utente.name.replace(/[^a-zA-Z0-9\s]/g, "");
        let nomeFoglio = cognome.substring(0, 31) + " " + name.substring(0, 31); // Ensure the sheet name does not exceed 31 characters
        // Check if the sheet name already exists
        let numFoglio = 1;

        let nomeFoglioFinale = nomeFoglio;
        let worksheetNames = wb.worksheets.map((ws) => ws.name);
        while (worksheetNames.includes(nomeFoglioFinale)) {
          nomeFoglioFinale = `${nomeFoglio}_${numFoglio}`;
          numFoglio++;
        }

        // Clone user data to avoid unexpected changes to the original data
        const dataUtente = JSON.parse(JSON.stringify(data));

        // Modify user-specific data
        dataUtente[4][6] = utente["codice_perito"]; // G6: 'codice perito'
        dataUtente[6][0] = utente["surname"]; // A7: 'cognome'
        dataUtente[6][3] = utente["titolo_name"]; // D7: 'titolo'
        dataUtente[6][4] = utente["name"]; // E7: 'nome'
        dataUtente[8][0] = utente["indirizzo"]; // A9: 'indirizzo'
        dataUtente[8][6] = utente["email"]; // G9: 'pec'
        dataUtente[10][0] = utente["codice_fiscale"]; // A11: 'codice fiscale'
        dataUtente[10][3] = utente["partita_iva"]; // D11: 'partita IVA'
        dataUtente[10][6] = utente["disponibilita"]; // G11: 'disponibilità'
        dataUtente[12][0] = utente["anno_nascita"]; // A13: 'anno di nascita'
        if (utente["albo"].length) {
          dataUtente[12][1] = utente["albo"][0]["iscrizione"];
          dataUtente[12][2] = utente["albo"][0]["professione"];
          dataUtente[12][4] = utente["albo"][0]["n_iscriz_prov"];
          dataUtente[12][6] = utente["albo"][0]["albo"];
        }
        if (utente["fatturato"].length) {
          utente["fatturato"] = utente["fatturato"].sort(
            (a, b) => a.anno - b.anno
          );

          utente["fatturato"].forEach((el, inde) => {
            if (!dataUtente[30 + inde]) {
              dataUtente[30 + inde] = [];
            }
            dataUtente[30 + inde][0] = el.anno;
            dataUtente[30 + inde][1] = el.giorni;
            dataUtente[30 + inde][2] = el.importo;
            dataUtente[30 + inde][4] = el.note;
          });
        }
        if (utente["result_partecipazioni_incontri"].length) {
          utente["result_partecipazioni_incontri"].forEach((el, inde) => {
            if (inde < 6) {
            dataUtente[22 + inde][0] = el.data_evento;
            dataUtente[22 + inde][1] = el.luogo;
            dataUtente[22 + inde][2] = el.riunione_prodotto;
            dataUtente[22 + inde][4] = el.note;
          }
          })
        }

        let cod_perito = utente["codice_perito"];
        let ruoli_anno = utente["ruoli_anno"];
        if (ruoli_anno[cod_perito]) {
          ruoli_anno[cod_perito].forEach((element) => {
            let valueGrade = this.getFraseDaAbbreviazione(element.name);
            switch (valueGrade) {
              case "ALL":
                dataUtente[15][0] = `ALL. : ${element.grade_anno}`;
                break;
              case "ASS":
                dataUtente[15][1] = `ASS. : ${element.grade_anno}`;
                break;
              case "ASP RIL":
                dataUtente[15][2] = `ASP. RIL. : ${element.grade_anno}`;
                break;
              case "RIL":
                dataUtente[15][4] = `RIL. : ${element.grade_anno}`;
                break;
              case "RIL SPEC":
                dataUtente[15][6] = `RIL. SPEC. : ${element.grade_anno}`;
                break;
              case "RS":
                dataUtente[17][0] = `R.S. : ${element.grade_anno}`;
                break;
              case "RSFI":
                dataUtente[17][1] = `R.S.F.I. : ${element.grade_anno}`;
                break;
              default:
            }
          });
        }

        const prodotti = utente["products"]?.split("|") || [];
        let prodottiJoin = [];
        prodotti?.forEach((prodotto) => {
          prodottiJoin.push(prodotto);
        });
        dataUtente[17][2] = prodottiJoin.join(" ");

        // Create a new sheet for the user
        let anno_inserimento = utente["anno_inserimento"];
        const ws = wb.addWorksheet(nomeFoglioFinale, {
          properties: {
            tabColor: {
              argb: anno_inserimento
                ? this.inserimento[anno_inserimento]
                : "#FFF",
            },
          },
        });

        // Set column widths
        ws.columns = Object.entries(columnWidths).map(([col, width]) => ({
          key: col,
          width: width,
        }));

        // Add data to the sheet
        dataUtente.forEach((row) => {
          ws.addRow(row);
        });

        /* unione celle -  cells merge */
        ws.mergeCells("A1:F6");

        // Add the image
        const base64Image = await this.convertImageToBase64(intestazioneImage);
        const imageId = wb.addImage({
          base64: base64Image,
          extension: "png",
        });

        ws.addImage(imageId, "A1:F6");

        ws.mergeCells("C18:H18");
        ws.mergeCells("C19:H19");
        ws.mergeCells("C20:H20");
        ws.mergeCells("A21:G21");
        ws.mergeCells("C22:G22");
        ws.mergeCells("C30:D30");
        ws.mergeCells("E30:H30");
        


        /* fatturato dinamico */
        let equlizator = 31;
        utente["fatturato"].forEach(() => {
          ws.mergeCells(`C${equlizator}:D${equlizator}`);
          ws.mergeCells(`E${equlizator}:H${equlizator}`);

          ws.getCell(`A${equlizator}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };

          ws.getCell(`B${equlizator}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };
          ws.getCell(`C${equlizator}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };

          ws.getCell(`E${equlizator}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };

          equlizator++;
        });

        /* incontri dinamico */
        let equlizator_incontri = 23;
        utente["result_partecipazioni_incontri"].forEach(() => {
          if (equlizator_incontri < 29) {
          
          ws.mergeCells(`C${equlizator_incontri}:D${equlizator_incontri}`);
          ws.mergeCells(`E${equlizator_incontri}:H${equlizator_incontri}`);

          ws.getCell(`A${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };

          ws.getCell(`G${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };
          ws.getCell(`H${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };

          ws.getCell(`E${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };
          ws.getCell(`B${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };
          ws.getCell(`C${equlizator_incontri}`).border = {
            top: { style: "double", color: { argb: "000000" } },
            left: { style: "double", color: { argb: "000000" } },
            bottom: { style: "double", color: { argb: "000000" } },
            right: { style: "double", color: { argb: "000000" } },
          };
        }
          equlizator_incontri++;
        });

        /* incontri */
        ws.getCell("A21").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        ws.getCell("G21").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };

        ws.getCell("H21").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        ws.getCell("B21").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };

        ws.getCell("A22").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        ws.getCell("G22").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };

        ws.getCell("H22").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        ws.getCell("B22").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        /* fatturato anno */

        ws.getCell("A30").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };

        ws.getCell("B30").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        ws.getCell("C30").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };

        ws.getCell("E30").border = {
          top: { style: "double", color: { argb: "000000" } },
          left: { style: "double", color: { argb: "000000" } },
          bottom: { style: "double", color: { argb: "000000" } },
          right: { style: "double", color: { argb: "000000" } },
        };
        /* fine fatturato anno */
      }

      // Save the workbook to a file
      wb.xlsx
        .writeBuffer()
        .then((buffer) =>
          saveAs.saveAs(new Blob([buffer]), `${Date.now()}_schedario.xlsx`)
        )
        .catch((err) => console.log("Error writing excel export", err));
    },

    exportToExcel() {

      const province = {
        "agrigento": "AG",
        "alessandria": "AL",
        "ancona": "AN",
        "arezzo": "AR",
        "ascoli piceno": "AP",
        "asti": "AT",
        "avellino": "AV",
        "bari": "BA",
        "barletta-andria-trani": "BT",
        "belluno": "BL",
        "benevento": "BN",
        "bergamo": "BG",
        "biella": "BI",
        "bologna": "BO",
        "bolzano": "BZ",
        "brescia": "BS",
        "brindisi": "BR",
        "cagliari": "CA",
        "caltanissetta": "CL",
        "campobasso": "CB",
        "carbonia-iglesias": "CI",
        "caserta": "CE",
        "catania": "CT",
        "catanzaro": "CZ",
        "chieti": "CH",
        "como": "CO",
        "cosenza": "CS",
        "cremona": "CR",
        "crotone": "KR",
        "cuneo": "CN",
        "enna": "EN",
        "fermo": "FM",
        "ferrara": "FE",
        "firenze": "FI",
        "foggia": "FG",
        "forli cesena": "FC",
        "frosinone": "FR",
        "genova": "GE",
        "gorizia": "GO",
        "grosseto": "GR",
        "imperia": "IM",
        "isernia": "IS",
        "la spezia": "SP",
        "l'aquila": "AQ",
        "latina": "LT",
        "lecce": "LE",
        "lecco": "LC",
        "livorno": "LI",
        "lodi": "LO",
        "lucca": "LU",
        "macerata": "MC",
        "mantova": "MN",
        "massa-carrara": "MS",
        "matera": "MT",
        "messina": "ME",
        "milano": "MI",
        "modena": "MO",
        "monza e brianza": "MB",
        "napoli": "NA",
        "novara": "NO",
        "nuoro": "NU",
        "ogliastra": "OG",
        "olbia-tempio": "OT",
        "oristano": "OR",
        "padova": "PD",
        "palermo": "PA",
        "parma": "PR",
        "pavia": "PV",
        "perugia": "PG",
        "pesaro e urbino": "PU",
        "pescara": "PE",
        "piacenza": "PC",
        "pisa": "PI",
        "pistoia": "PT",
        "pordenone": "PN",
        "potenza": "PZ",
        "prato": "PO",
        "ragusa": "RG",
        "ravenna": "RA",
        "reggio calabria": "RC",
        "reggio emilia": "RE",
        "rieti": "RI",
        "rimini": "RN",
        "roma": "RM",
        "rovigo": "RO",
        "salerno": "SA",
        "sassari": "SS",
        "savona": "SV",
        "siena": "SI",
        "siracusa": "SR",
        "sondrio": "SO",
        "taranto": "TA",
        "teramo": "TE",
        "terni": "TR",
        "torino": "TO",
        "trapani": "TP",
        "trento": "TN",
        "treviso": "TV",
        "trieste": "TS",
        "udine": "UD",
        "varese": "VA",
        "venezia": "VE",
        "verbania": "VB",
        "vercelli": "VC",
        "verona": "VR",
        "vibo valentia": "VV",
        "vicenza": "VI",
        "viterbo": "VT"
      }
      var property_to_remove = [];

      // elimino alcuni campi da non mostrare nel file excel

      this.periti_attivi.forEach((el) => {
        let obj = {};
        Object.keys(el).forEach(function (key) {
          let lista_for_table = [
            "id",
            "products_id",
            "titolo_id",
            "titolo_studio_id",
            "regime_id",
            "grade_id",
            "onorario_id",
            "fatturato",
            "ruoli_anno",
            "albo",
            "result_partecipazioni_incontri"

          ];
          if (key == "provincia" && el[key]) {
            let toLower = el[key]?.toLowerCase() || ''
            toLower = toLower.trim();
            if(toLower && province[toLower]){
              el[key] =   province[toLower]
            }
          } 
          if (!lista_for_table.includes(key)) {
            obj[key] = el[key];
          }
        });
        property_to_remove.push(obj);
      });

      // Crea un foglio di lavoro
      const worksheet = XLSX.utils.json_to_sheet(property_to_remove);

      // Crea una cartella di lavoro
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");

      // Genera un file Excel
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Salva il file Excel
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      let date_now = new Date().toLocaleDateString();
      saveAs(blob, `Periti_attivi${date_now}.xlsx`);
    },

    // General debounce
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },

    // filter_periti
    filter_codice_perito() {
      // Filtra i periti attivi basandosi sul codice perito che contiene la stringa di ricerca
      this.filtered_periti = this.periti_attivi.filter((perito) =>
        perito.codice_perito.includes(this.search_perito)
      );
    },

    // filter periti cognome
    filter_cognome_perito() {
      // Filtra i periti attivi basandosi sul codice perito che contiene la stringa di ricerca
      this.filtered_periti = this.periti_attivi.filter((perito) =>
        perito.surname.includes(this.search_cognome.toUpperCase() || this.search_cognome.toLowerCase() )
      );
    },

    updateSelector(_, item) {
      this.perito_selected = item;
    },

    async get_periti_attivi() {
      const _resp = await userApi.get_tabella_periti().catch(() => {});

      if (_resp) {
        let sortedBySurname =  _resp.data.users.sort((a,b) => (a.surname > b.surname) ? 1 : ((b.surname > a.surname) ? -1 : 0))
        this.periti_attivi = sortedBySurname;
        this.filtered_periti = sortedBySurname;
        this.data = { current_page: 1 };
      }
    },
    orderBy(_orderBy) {
      this.search.orderBy = _orderBy;
      this.get_specifiche();
    },
    perPage() {
      this.search.page = 1;
      this.get_specifiche();
    },
    paginateClick(pageNum) {
      this.search.page = pageNum;
      this.get_specifiche();
    },
    dayClicked(data) {
      this.dateRange = data;
      this.get_specifiche();
    },
    infoCertificato(perito) {
      this.perito_selezionato = perito;
      this.isModalInfoVisible = true;
    },
    close_modal_callback() {
      this.isModalInfoVisible = false;
      this.selected_specifica = null;
    },
    resetAll() {
      this.search.per_page = 10;
      this.search.page = 1;

      for (const key in this.dateRange) {
        if (this.dateRange.hasOwnProperty(key)) {
          this.dateRange[key] = null;
        }
      }
      this.get_specifiche();
    },
    /*async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },*/
  },
  mounted() {
    //this.get_lista_ispettori()
    //this.get_consorzi()
    // this.get_agenzie()
    this.get_periti_attivi();
  },
  created() {
    // Creiamo la funzione debounced e la memorizziamo in debounceFunc
    this.debounceFunc = this.debounce(this.filter_codice_perito, 300);
    this.debounceCognomeFunc = this.debounce(this.filter_cognome_perito, 300);
  },
};
</script>
<style>
span.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
span.label-danger {
  background-color: #d26a5c;
}
span.label-success {
  background-color: #60d25c;
}
.row-custom-periti-attivi {
  flex-wrap: nowrap !important;
  justify-content: space-evenly !important;
  gap: 15px;
}
.btn-periti-attivi {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --jexcel-border-color: #000;
  --button-color: #298ba8;
  --active-color: #007aff;
  --vs-colors--lightest: rgba(60, 60, 60, 0.26);
  --vs-colors--light: rgba(60, 60, 60, 0.5);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 0.15);
  --vs-search-input-color: inherit;
  --vs-search-input-bg: #fff;
  --vs-search-input-placeholder-color: inherit;
  --vs-font-size: 1rem;
  --vs-line-height: 1.4;
  --vs-state-disabled-bg: #f8f8f8;
  --vs-state-disabled-color: var(--vs-colors--light);
  --vs-state-disabled-controls-color: var(--vs-colors--light);
  --vs-state-disabled-cursor: not-allowed;
  --vs-border-color: var(--vs-colors--lightest);
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-radius: 4px;
  --vs-actions-padding: 4px 6px 0 3px;
  --vs-controls-color: var(--vs-colors--light);
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;
  --vs-selected-bg: #f0f0f0;
  --vs-selected-color: var(--vs-colors--dark);
  --vs-selected-border-color: var(--vs-border-color);
  --vs-selected-border-style: var(--vs-border-style);
  --vs-selected-border-width: var(--vs-border-width);
  --vs-dropdown-bg: #fff;
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 350px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--vs-dropdown-color);
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #5897fb;
  --vs-dropdown-option--active-color: #fff;
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;
  --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
  --vs-transition-duration: 0.15s;
  --vs-disabled-bg: var(--vs-state-disabled-bg);
  --vs-disabled-color: var(--vs-state-disabled-color);
  --vs-disabled-cursor: var(--vs-state-disabled-cursor);
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #5c80d1;
  border-color: #5c80d1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer;
}
.con-btn-periti-attivi {
  display: flex;
  align-items: center;
}

.container-input-search-schedario {
  display: flex;
}

.pointer-table {
  cursor: ns-resize;
}
</style>
