<template>
    <div style="width:100%">
        <transition name="modal"  v-if="showModal" @close="showModal = false">
            <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-partite">

                <div class="modal-header">
                    <slot name="header">

                    </slot>
                </div>

                <div class="modal-body">
                    <div id="leaflet"></div>
                </div>

                <div class="modal-footer">
                    <slot name="footer">
                    <input type="checkbox" name="satellite" @click="updateSatellite" class="btn-select-all"/> Satellite<br/>
                    <button class="modal-default-button" @click="chiudi_modal">
                        OK
                    </button>
                    </slot>
                </div>
                </div>
            </div>
            </div>
        </transition>
        <div class="block">
            <div class="block-content block-content-full">
                <h3 v-if="partite.length == 0">Nessuna partita presente</h3>
                <div v-if="partite.length > 0" class="row" style="margin-bottom:10px;">
                    <div style="margin-top:30px;" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div v-if="!data_evasione && !readOnly && canPerito('edit|full')" class="custom-control custom-checkbox mb-1">
                                <input @change="selezionaTutto" v-model="seleziona_tutto" type="checkbox" class="custom-control-input" id="seleziona_tutto" name="seleziona_tutto">
                                <label class="custom-control-label" for="seleziona_tutto">Seleziona tutte le Partite</label>
                            </div>
                        </div>
                    </div>
                </div>
                <table v-if="partite.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                    <thead>
                        <tr>
                            <th class="text-center">SELEZIONATA</th>
                            <th class="d-none d-sm-table-cell">PERVENUTA</th>
                            <th class="d-none d-sm-table-cell">NR.</th>
                            <th class="d-none d-sm-table-cell">VARIETÀ</th>
                            <th class="d-none d-sm-table-cell">IMPORTO ASSICURATO</th>
                            <th class="d-none d-sm-table-cell">FG. CAT.</th>
                            <th class="d-none d-sm-table-cell">LIQUIDATA</th>
                            <th style="text-align:center;">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(partita, index) in partite" :key="index">
                            <td class="font-size-sm">
                                <input v-if="!data_evasione && !readOnly && canPerito('edit|full')" @change="changePartitaSelezionata(partita)" type="checkbox" v-model="partita.selezionata">
                            </td>
                            <td class="font-size-sm">
                                <div v-if="partita.caricamento_ordine == 'manuale'" style="width: 10px;height: 10px;background: red;border-radius: 100%;margin:0 auto;"></div>
                                <div v-else style="width: 10px;height: 10px;background: green;border-radius: 100%;margin:0 auto;"></div>
                            </td>
                            <td class="font-size-sm">
                                {{partita.numero_partita}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.varieta.etichetta}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{ partita.importo_assicurato | toCurrency}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.foglio_catastale}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <i v-if="partita.denunce_evasione_status == 'da_approvare'" class="si si-check text-danger"></i> 
                                <i v-if="partita.denunce_evasione_status == 'approvati' && partita.partita_has_all_denunce_bollettino && partita.denunce.length > 0" class="si si-check text-success"></i> 
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <div class="btn-group">
                                    <button v-if="partita.caricamento_ordine == 'manuale' && partita.bollettino_relation_count === 0 && !data_evasione && !readOnly && canPerito('full|edit')" @click="editOrCreatePartita(partita)" type="button" class="btn btn-sm btn-light" title="Edit Partita">
                                        <i class="fa fa-fw fa-eye"></i>
                                    </button>
                                    <button  v-if="!readOnly && !partita.sub_partite_created && !certificato.last_bollettino_number" @click="subPartite(partita)" class="btn btn-sm btn-light" type="button" title="Modal Sub Partita">
                                        <i class="fas fa-plus"></i> {{partita.sub_partite.length}}
                                    </button>
                                    <button @click="openDenunceModal(partita)" class="btn btn-sm btn-light" type="button" title="Modal Denunce">
                                        <i class="fas fa-list"></i>
                                        <div v-if="!partita.partita_has_all_denunce_bollettino && partita.denunce.length > 0" style="width:5px;height:5px;background:red;border-radius:100%;position:absolute;top:5px;right:0;"></div>
                                    </button>
                                    <button v-if="partita.caricamento_ordine == 'manuale' && partita.denunce.length === 0 && !data_evasione && canPerito('full|edit')" @click="deletePartita(partita)" class="btn btn-sm btn-light" type="button" title="Modal Denunce">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <button @click="showParticella(partita.id)" :class="coordinates.some(obj => obj.hasOwnProperty(partita.id)) ? 'green': 'grey'">
                                        <i class="fa fa-map-marked-alt"  >
                                        </i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
                <div v-if="paginate.page_count > 1" class="col-sm-12 col-md-12">
                    <paginate
                        v-model="paginate.page"
                        :page-count="paginate.page_count"
                        :click-handler="paginateClick"
                        :prev-text="'Precedente'"
                        :next-text="'Successiva'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :prev-class="'prev'"
                        :next-class="'next'">
                    </paginate>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" v-if="!readOnly">
                    <button v-if="data_evasione && canPerito('full')" v-on:click.prevent="newRicolpita" class="btn btn-primary">RICOLPITA</button>
                    <button v-if="!editMode && !data_evasione && canPerito('full|edit')" v-on:click.prevent="editOrCreatePartita" class="btn btn-primary">NUOVA PARTITA</button>
                    <button style="margin-left:10px;" v-if="isPartiteSelected && !data_evasione && canPerito('full|edit')" v-on:click.prevent="creaNuovaDenuncia" class="btn btn-primary">NUOVA DENUNCIA</button>
                    <button style="margin-left:10px;" v-if="isPartiteSelectedAndDenunce && !data_evasione && canPerito('full|edit')" v-on:click.prevent="openModalSelectSubPartite" class="btn btn-primary">CREA BOLLETTINO</button>
                    <button style="margin-left:10px;" v-if="!editMode && canEvadi && canPerito('full')" v-on:click.prevent="evadiOrdine" class="btn btn-primary">EVADI</button>
                    <button style="margin-left:10px;" v-if="!editMode && !canEvadi && canPerito('full') && bozzaApprovato" disabled class="btn btn-primary">IN ATTESA DI APPROVAZIONE</button>
                </div>
            </div>
        </div>

        <!-- denunce eliminate-->
        <div class="block">
            <div class="block-content block-content-full">
                <h3 v-if="partiteDenunceDeleted.length == 0">Nessuna denuncia eliminata</h3>
                <h3 v-if="partiteDenunceDeleted.length > 0">Denunce eliminate</h3>
                <table v-if="partite.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                    <thead>
                        <tr>
                            <th class="text-center">SELEZIONATA</th>
                            <th class="d-none d-sm-table-cell">PERVENUTA</th>
                            <th class="d-none d-sm-table-cell">NR.</th>
                            <th class="d-none d-sm-table-cell">VARIETÀ</th>
                            <th class="d-none d-sm-table-cell">IMPORTO ASSICURATO</th>
                            <th class="d-none d-sm-table-cell">FG. CAT.</th>
                            <th class="d-none d-sm-table-cell">LIQUIDATA</th>
                            <th style="text-align:center;">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(partita, index) in partiteDenunceDeleted" :key="index">
                            <td class="font-size-sm">
                                <input v-if="!data_evasione && !readOnly && canPerito('edit|full')" @change="changePartitaSelezionata(partita)" type="checkbox" v-model="partita.selezionata">
                            </td>
                            <td class="font-size-sm">
                                <div v-if="partita.caricamento_ordine == 'manuale'" style="width: 10px;height: 10px;background: red;border-radius: 100%;margin:0 auto;"></div>
                                <div v-else style="width: 10px;height: 10px;background: green;border-radius: 100%;margin:0 auto;"></div>
                            </td>
                            <td class="font-size-sm">
                                {{partita.numero_partita}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.varieta.etichetta}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{ partita.importo_assicurato | toCurrency}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.foglio_catastale}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <i v-if="partita.denunce_evasione_status == 'da_approvare'" class="si si-check text-danger"></i> 
                                <i v-if="partita.denunce_evasione_status == 'approvati' && partita.partita_has_all_denunce_bollettino && partita.denunce.length > 0" class="si si-check text-success"></i> 
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <div class="btn-group">
                                    <button @click="openDenunceModal(partita)" class="btn btn-sm btn-light" type="button" title="Modal Denunce">
                                        <i class="fas fa-list"></i>
                                        <div v-if="!partita.partita_has_all_denunce_bollettino && partita.denunce.length > 0" style="width:5px;height:5px;background:red;border-radius:100%;position:absolute;top:5px;right:0;"></div>
                                    </button>
                                    <button @click="showParticella(partita.id)" :class="coordinates.some(obj => obj.hasOwnProperty(partita.id)) ? 'green': 'grey'">
                                        <i class="fa fa-map-marked-alt"  >
                                        </i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
                <div v-if="paginate2.page_count > 1" class="col-sm-12 col-md-12">
                    <paginate
                        v-model="paginate2.page"
                        :page-count="paginate2.page_count"
                        :click-handler="paginateClick2"
                        :prev-text="'Precedente'"
                        :next-text="'Successiva'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :prev-class="'prev'"
                        :next-class="'next'">
                    </paginate>
                </div>
                
            </div>
        </div>
        <!-- denunce eliminate-->
        <ModalPartita 
            :varieta="varieta" 
            :isModalVisible="isModalPartitaVisible" 
            ref="modal_partita" 
            :ordine_id="certificato_id"
            :ispettore_id="ispettore_id" 
            @closeModalCallback="close_partite_modal">
        </ModalPartita>
        
        <ModalSubPartita
            :isModalVisible="isModalSubPartitaVisible" 
            :id_ispettore="ispettore_id"
            ref="modal_sub_partita" 
            @closeModalCallback="close_modal_sub_partite">
        </ModalSubPartita>

        <ModalSubPartitaDeleteAll
            :partita="partita_selezionata"
            :id_ispettore="ispettore_id"
            :isModalVisible="isModalSubPartitaDeleteAllVisible" 
            ref="modal_sub_partita_delete_all" 
            @closeModalCallback="close_partite_modal">
        </ModalSubPartitaDeleteAll>

        <ModalDenunce
            :readOnly="readOnly"
            :data_evasione="data_evasione"
            :isModalVisible="isModalDenunceVisible" 
            :ordine_id="certificato_id"
            :ispettore_id="ispettore_id"
            :ispettorePermission="ispettorePermission"
            :is_direzione="is_direzione"
            ref="modal_denunce" 
            @closeModalCallback="close_modal_denunce_list">
        </ModalDenunce>
        
        <ModalEditDenuncia
            :isModalVisible="isModalEditDenunciaVisible"
            ref="modal_edit_denuncia" 
            :eventi="eventi"
            :ispettore_id="ispettore_id"
            :ispettorePermission="ispettorePermission"
            @closeModalCallback="close_modal_denuncia">
        </ModalEditDenuncia>
        
        <ModalSelectPartiteSubPartite
            :isModalVisible="isModalSelectPartiteSubPartiteVisible"
            ref="modal_select_partite_sub_partite"
            @closeModalCallback="close_modal_select_partite_sub_partite">
        </ModalSelectPartiteSubPartite>
        
        <ModalSelectPartiteDenunce
            :isModalVisible="isModalSelectPartiteDenunceVisible"
            ref="modal_select_partite_denunce"
            @closeModalCallback="close_modal_select_partite_denunce">
        </ModalSelectPartiteDenunce>

        <ModalDataEvasione
            :isModalVisible="isModalDataEvasioneVisible"
            ref="modal_data_evasione"
            @closeModalCallback="close_modal_data_evasione">
        </ModalDataEvasione>
        
        <ModalSelectDataPerizia
            :isModalVisible="isModalSelectDataPeriziaVisible"
            :ordine_id="certificato_id"
            :ispettore_id="ispettore_id"
            ref="modal_select_data_perizia"
            @closeModalCallback="close_modal_select_partite_denunce">
        </ModalSelectDataPerizia>
        

    </div>
</template>
<script src="https://unpkg.com/leaflet/dist/leaflet-src.js"></script>
<link type="text/css" rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
<script>
import CertificatoRepository from "../../services/apis/certificato"

import Paginate from 'vuejs-paginate'
import ModalPartita from "../../components/Certificato/ModalPartita"
import ModalDenunce from "../../components/Certificato/ModalDenunce"
import ModalEditDenuncia from "../../components/Certificato/ModalEditDenuncia"
import ModalSubPartita from "../../components/Certificato/ModalSubPartita"
import ModalSubPartitaDeleteAll from "../../components/Certificato/ModalSubPartitaDeleteAll"
import ModalSelectPartiteSubPartite from "../../components/Certificato/ModalSelectPartiteSubPartite"
import ModalSelectPartiteDenunce from "../../components/Certificato/ModalSelectPartiteDenunce"
import ModalDataEvasione from "../../components/Certificato/ModalDataEvasione"
import ModalSelectDataPerizia from "../../components/Certificato/ModalSelectDataPerizia"
import Utility from "../../services/utility"
import CatastoApi from "../../services/apis/catasto"
import * as proj4 from '../../views/Admin/proj4';
import * as Proj from '../../views/Admin/leaftproj4';
export default {
    props:['ispettore_id', 'certificato_id', 'editMode', 'varieta', 
    'eventi', 'certificato', 'agenzie', 'consorzio', 'comuni', 'specie', 'canEvadi', 'data_evasione', 'readOnly', 'ispettorePermission', 'is_direzione'],
    components: {
        ModalPartita, ModalSubPartita, ModalSubPartitaDeleteAll, ModalDenunce, 
        ModalEditDenuncia, Paginate, ModalSelectPartiteSubPartite, ModalSelectPartiteDenunce, ModalDataEvasione, ModalSelectDataPerizia
    },
    data(){
        return {
            partite:[],
            partiteDenunceDeleted:[],
            bozzaApprovato:false,
            paginate: {
                page_count:1,
                page:1,
                per_page:10,
                original_data:[],
                tmpCoordinate:[],
            },
            paginate2: {
                page_count:1,
                page:1,
                per_page:10,
                original_data:[],
                tmpCoordinate:[],
            },
            search: {
                ispettore_id:0,
                ordine_id: 0
            },
            isModalPartitaVisible:false,
            isModalSubPartitaVisible:false,
            isModalSubPartitaDeleteAllVisible:false,
            isModalDenunceVisible:false,
            isModalEditDenunciaVisible:false,
            isModalSelectPartiteSubPartiteVisible:false,
            isModalSelectPartiteDenunceVisible:false,
            isModalDataEvasioneVisible:false,
            isModalSelectDataPeriziaVisible:false,
            partita_selezionata:null,
            seleziona_tutto:false,
            partite_selezionate_bollettino:[],
            coordinates:[],
            showModal:false,
            mapIsActive:false,
            isSatellite:0.2
        }
    },
    computed: {
        isPartiteSelected(){
            const _partite_ids = this.paginate.original_data.filter((el) => {
                if(el.selezionata){
                    return el.id
                }
            })

            return _partite_ids.length > 0 ? true : false
        },
        isPartiteSelectedAndDenunce(){
            const _partite_ids = this.paginate.original_data.filter((el) => {
                if(!el.partita_has_all_denunce_bollettino && el.selezionata){
                    return el.id
                }
            })

            return _partite_ids.length > 0 ? true : false
        },
        canEvadiOrdine(){
            const partite_approvate = this.paginate.original_data.filter((el) => {
                if(el.denunce_evasione_status == "approvati"){
                    return el.id
                }
            })

            if(this.paginate.original_data.length === partite_approvate.length){
                return true
            }

            return false
        },
    },
    methods: {
        canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        updateSatellite (e) {
            if(e.target.checked) {
                this.isSatellite = 1
                this.createMap(1,1)
            }else {
                this.isSatellite = 0.2
                this.createMap(1,1)
            }
        },
        async get_bozza_approvation(){
            const _resp = await CertificatoRepository.get_certificato(this.certificato_id, this.ispettore_id).catch(() => {})
            this.bozzaApprovato = _resp.data.data.bozza_approvato
        },
        async get_partite(refresh=false){
            this.search.ordine_id = this.certificato_id
            this.search.ispettore_id = this.ispettore_id

            this.get_bozza_approvation()

            const _resp = await CertificatoRepository.get_partite_by_ordine_id(this.search).catch(() => {})

            if(_resp){
                this.partite = _resp.data.data
                this.partiteDenunceDeleted = _resp.data.data_deleted

                this.paginate.original_data = this._.cloneDeep(this.partite, true)
                this.paginate2.original_data = this._.cloneDeep(this.partiteDenunceDeleted, true)
                
                this.$emit('callBackPartite', this._.cloneDeep(this.partite, true), refresh);
                

                this.paginate.page_count = Math.ceil(this.paginate.original_data.length / this.paginate.per_page);
                this.paginate2.page_count = Math.ceil(this.paginate2.original_data.length / this.paginate2.per_page);

                this.partita_selezionata = null
                this.seleziona_tutto = false
                this.fakePagination()
                this.fakePagination2()
            }
        },
        paginateClick(pageNum){
            this.paginate.page = pageNum
            this.fakePagination()
        },
        paginateClick2(pageNum){
            this.paginate2.page = pageNum
            this.fakePagination2()
        },
        editOrCreatePartita(partita){
            if(partita){
                if(this.$refs.modal_partita){
                    this.$refs.modal_partita.editPartita(this._.cloneDeep(partita))
                }
            }
            this.isModalPartitaVisible = true
        },
        close_partite_modal(refresh){            
            if(refresh){
                this.get_partite()
            }else{
                this.partita_selezionata = null
            }
            this.isModalSubPartitaVisible = false
            this.isModalPartitaVisible = false
            this.isModalSubPartitaDeleteAllVisible = false
            
        },
        close_modal_sub_partite(type){
            if(type == 'delete_all'){
                this.isModalSubPartitaVisible = false
                this.isModalSubPartitaDeleteAllVisible = true
                return
            }else if(type == 'refresh'){
                this.get_partite()
            }
            this.partita_selezionata = null
            this.close_partite_modal()
        },
        subPartite(partita){            
            this.isModalSubPartitaVisible = true
            this.partita_selezionata = partita
            this.$refs.modal_sub_partita.calcolateSubPartita(partita)
        },
        openDenunceModal(partita){
            this.partita_selezionata = partita
            this.$refs.modal_denunce.addDenunce(partita)
            this.isModalDenunceVisible = true
        },
        async close_modal_denunce_list(obj){
            if(!obj){
                this.partita_selezionata = null
                this.isModalDenunceVisible = false
                return
            }

            if(typeof obj == 'string'){
                const _partita_selezionata = this._.cloneDeep(this.partita_selezionata, true)
                await this.get_partite(true)
                const find = this.partite.find((el) => {
                    return el.id == _partita_selezionata.id
                })    
                this.partita_selezionata = this._.cloneDeep(find)
                this.$refs.modal_denunce.addDenunce(find)
                return
            }
            
            this.modal_edit_denunce(obj)
        },
        modal_edit_denunce(obj){            
            this.$refs.modal_edit_denuncia.addDenuncia(obj, this.certificato_id)
            this.isModalEditDenunciaVisible = true
        },
        async close_modal_denuncia(refresh){            
            if(refresh){

                if(!this.partita_selezionata){
                    await this.get_partite(true)
                    this.isModalEditDenunciaVisible = false
                    return
                }

                const _partita_selezionata = this._.cloneDeep(this.partita_selezionata, true)
                
                await this.get_partite(true)
                
                const find = this.partite.find((el) => {
                    return el.id == _partita_selezionata.id
                })

                this.partita_selezionata = this._.cloneDeep(find, true);
                this.$refs.modal_denunce.addDenunce(find)
                this.isModalDenunceVisible = true
            }

            this.isModalEditDenunciaVisible = false
            
            
        },
        selezionaTutto(){
            this.paginate.original_data = this.paginate.original_data.map((el) => {
                el.selezionata = this.seleziona_tutto
                return el
            })
            this.fakePagination()
        },
        changePartitaSelezionata(partita){
            this.paginate.original_data = this.paginate.original_data.map((el) => {
                if(partita.id == el.id){
                    el.selezionata = partita.selezionata
                }
                return el
            })
            
            var found = this.paginate.original_data.find((el) => {
                return !el.selezionata 
            })

            if(!found){
                this.seleziona_tutto = true
            }else{
                this.seleziona_tutto = false
            }

            this.fakePagination()
        },
        fakePagination(){
            const {per_page, original_data, page} = this.paginate
             this.partite = original_data.slice((page - 1) * per_page, page * per_page) 

             setTimeout(() => {
                this.partite.forEach(partita => {
                    this.canDisplayCoordinate(partita.id) 
                });
            }, 1000);
        },

        fakePagination2(){
            const {per_page, original_data, page} = this.paginate2
             this.partiteDenunceDeleted = original_data.slice((page - 1) * per_page, page * per_page) 

             setTimeout(() => {
                this.partiteDenunceDeleted.forEach(partita => {
                    this.canDisplayCoordinate(partita.id) 
                });
            }, 1000);
        },
        creaNuovaDenuncia(){
            const _partite_ids = this.paginate.original_data.filter((el) => {
                if(el.selezionata){
                    return el
                }
            }).map((el) => {
                return el.id
            })

            this.$refs.modal_edit_denuncia.addDenuncia({partita_ids:_partite_ids}, this.certificato_id)
            this.isModalEditDenunciaVisible = true
        },
        openModalSelectSubPartite(){

            let _partite = this._.cloneDeep(this.paginate.original_data, true)
            let sub_partite = []
            
            _partite = _partite.map((partita) => {
                if(partita.partita_has_all_denunce_bollettino){
                    partita.selezionata = false
                }

                if(partita.selezionata && partita.sub_partite.length > 0){
                    partita.sub_partite.forEach(sub_partita => {
                        sub_partite.push(sub_partita)
                    });
                }

                return partita
            })

            if(sub_partite.length > 0){
                this.$refs.modal_select_partite_sub_partite.setPartiteSelezionate(_partite)
                this.isModalSelectPartiteSubPartiteVisible = true
            }else{
                this.partite_selezionate_bollettino = _partite
                this.openModalSelectDataPerizia()
            }
        },
        close_modal_select_partite_sub_partite(partite){
            this.isModalSelectPartiteSubPartiteVisible = false
            
            if(partite){
                this.partite_selezionate_bollettino = this._.cloneDeep(partite)
                // this.openModalSelectDenunce()
                this.openModalSelectDataPerizia()


            }
        },
        openModalSelectDataPerizia(){
            this.$refs.modal_select_data_perizia.get_data(this.partite_selezionate_bollettino)
            this.isModalSelectDataPeriziaVisible = true
        },
        openModalSelectDenunce(){
            this.$refs.modal_select_partite_denunce.setPartiteSelezionate(this.partite_selezionate_bollettino)
            this.isModalSelectPartiteDenunceVisible = true
        },
        close_modal_select_partite_denunce(data, data_perizia){
            this.isModalSelectDataPeriziaVisible = false
            
            //this.isModalSelectPartiteDenunceVisible = false
            if(!data){
                return
            }

            let certificato = this._.cloneDeep(this.certificato, true)
            const agenzia = this.agenzie.find((el) => el.id === certificato.agenzia_id)
            const consorzio = this.consorzio.find((el) => el.id === certificato.consorzio_id)
            const specie = this.specie.find((el) => el.id === certificato.specie_id)
            const comune = this.comuni.find((el) => el.id === certificato.comune_id)
            certificato.agenzia = agenzia.nome+' - '+agenzia.codice_identificativo
            certificato.contraente = consorzio.name+' - '+consorzio.codice
            certificato.specie = specie.etichetta+' - '+specie.codice_identificativo
            certificato.comune = comune.nome
            certificato.data_perizia = data_perizia
            //data_perizia
            const _bollettino = {
                certificato:certificato,
                partite:data
            }

            
            this.$store.dispatch('updateBollettinoState',_bollettino)
            this.$router.push({name:'nuovo-bollettino',parameters:{ispettore_id:this.ispettore_id} })    
        
        },
        close_modal_data_evasione(){
            this.isModalDataEvasioneVisible = false
        },

        async newRicolpita(){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await CertificatoRepository.new_ricolpita({ordine_id:this.certificato_id, ispettore_id:this.ispettore_id}).catch(() => {})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)

                await this.get_partite();
                this.$emit('callBackPartite', this._.cloneDeep(this.partite, true), 'refresh');

            }
            this.$store.dispatch('updateLoadingState', false)
        },

        async evadiOrdine(){
            this.$refs.modal_data_evasione.get_data(this.certificato.ultima_ricolpita[0].id, this.ispettore_id)
            this.isModalDataEvasioneVisible = true
        },
        async deletePartita(partita){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await CertificatoRepository.delete_partita(partita.id, this.ispettore_id).catch(() => {})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.get_partite()
            }

            this.$store.dispatch('updateLoadingState', false)

        },




        async canDisplayCoordinate(id_denuncia) {
        try {
            const resp = await CatastoApi.single_coordinate(id_denuncia);
            if (resp.data.data === "null") {
            console.log("non ci sono coordinate");
            return false;
            } else {
            let coordinate_array = JSON.parse(resp.data.data);
            this.coordinates = [...this.coordinates, { [id_denuncia]: coordinate_array }];
            return true;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
},
        showParticella (id_denuncia) {
            // elimino tutti gli elementi duplicati dall'array
           let coordinate = this.coordinates.find((coordinate) => {
                if(coordinate[id_denuncia]){
                    return coordinate[id_denuncia]
                }
            })
            //console.log(coordinate[id_denuncia])
            if(coordinate){
                this.showModal = true
            }
            setTimeout(() => {
                if(!coordinate){
                    return false
                }else {
                    this.createMap(coordinate[id_denuncia])
                    console.log(coordinate[id_denuncia])
                }
                
            }, 1500);
            
            //return coordinate[id_denuncia]
        },

        chiudi_modal(){
            this.showModal = false
        },

        createMap (coordinate,satellite) {
            if(!coordinate){
                chiudi_modal()
                return
            }

            if(satellite){
                coordinate = [...this.tmpCoordinate]
            }else {
                this.tmpCoordinate = [...coordinate]
            }

            proj4.defs('EPSG:6706', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
            const Bbox_width = 18.99 - 5.93;
            const startResolution = Bbox_width / 1024;
            const resolutions = new Array(22);
                for (var i = 0; i < 22; ++i) {
                    resolutions[i] = startResolution / Math.pow(2, i);
                }


            var crs_6706 = new Proj.CRS(
                "EPSG:6706",
                "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs",
                {
                bounds: L.bounds([5.93, 34.76], [18.99, 47.1]),
                resolutions,
                /*origin: [coordinate[0][0][0],coordinate[0][0][1]],*/
                origin: [0,0],
                }
            );

            if(this.mapIsActive) {
                this.map.remove();
            }
            var layer = ""
            var baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    maxZoom: 70,
                });

                var cadastralLayer = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "CP.CadastralParcel",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.6,
                    attribution:
                        'Geoportale Cartografico Catastale © <a href="https://geoportale.cartografia.agenziaentrate.gov.it/">Geoportale</a> contributors'
                });
                var vestizioni = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "vestizioni",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.8
                });

              var satellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
                    minZoom: 10,
                    maxZoom: 50,
                    subdomains:['mt1'],
                    transparent: true,
                    opacity: this.isSatellite
                });
                //var layer = ""
                this.map = L.map("leaflet", {
                    layers: [baseLayer,cadastralLayer,vestizioni,satellite],
                    center: [0,0],
                    zoom: 0,
                   /* crs: crs_6706*/
                });

                this.mapIsActive = true
  
                const polygonStyle = {
                    fillColor: 'white',
                    weight: 4,
                    opacity: 1,
                    color: 'red',  
                    fillOpacity: 0.0
                }
                if(coordinate.length > 1) {
                    // creo tanti poligoni quante sono le coordinate
                    var multiplePolygon = [

                    ]

                    coordinate.forEach((item,index) => {
                        console.log(item)
                        var polygon = []
                        multiplePolygon.push(
                            polygon[index]={
                                type: "Polygon",
                                coordinates: [item],
                        })
                    })

                    multiplePolygon.forEach((element,index) => {
                        console.log(index)
                        layer = L.geoJSON(element);
                
                        layer.setStyle(polygonStyle);
                
                        this.map.addLayer(layer);

                    });

                    this.map.fitBounds(layer.getBounds());
                                
                }else {
                    const polygon = {
                    type: "Polygon",
                    coordinates: coordinate,
                    
                };

                layer = L.geoJSON(polygon);
                
                layer.setStyle(polygonStyle);
        
                this.map.addLayer(layer);
                this.map.fitBounds(layer.getBounds());
                }

    }
        
    },


    mounted(){
        this.get_partite()
    }
}
</script>


<style>
.grey{
    display: none !important;
    cursor: not-allowed;
    pointer-events: none;
    color:transparent !important;
    margin-left: 4px;
    border: none !important;
    background-color: transparent !important;
    font-size: 20px;
    
}

.green {
    cursor: pointer;
    pointer-events: all;
    color:rgb(0, 0, 0);
    margin-left: 4px;
    border: none;
    background-color: #f8f9fa;
    font-size: 20px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container-partite {
    width: 80vw;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  
  .modal-body {
    margin: 20px 0;
  }
  
  .modal-default-button {
    float: right;
  }
  
  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 0;
  }
  
  .modal-enter .modal-container-partite,
  .modal-leave-active .modal-container-partite {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>