<template>
    <!-- Side Overlay-->
        <nav id="sidebar" aria-label="Main Navigation">
            <!-- Side Header -->
            <div class="content-header bg-white-5">
                <!-- Options -->
                <div>
                    <!-- Color Variations -->
                    <div class="dropdown d-inline-block">
                        <a class="text-dual font-size-sm" id="sidebar-themes-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                            <i style="font-weight:bold" class="fas fa-bars text-primary"></i> <span style="font-size: 16px;font-weight: bold;">MENU</span>
                        </a>
                    </div>

                    <!-- Close Sidebar, Visible only on mobile screens -->
                    <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                    <a id="sidebar-close" style="position: absolute;right: 20px;" class="d-lg-none text-dual ml-3" data-toggle="layout" data-action="sidebar_close">
                        <i class="fa fa-times"></i>
                    </a>
                    <!-- END Close Sidebar -->
                </div>
                <!-- END Options -->
            </div>
            <!-- END Side Header -->

            <!-- Side Navigation -->
            <div class="content-side content-side-full" style="overflow-y: auto;height: 100%;padding-bottom: 100px;">
                <ul v-if="choise == 'specifiche'" class="nav-main">
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/scegli-sezione')" :class="[current == '/scegli-sezione' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Cambia Sezione</span>
                        </a>
                    </li>
                    <li v-show="show('all')" class="nav-main-item">
                        <a @click="routeChange('/dashboard')" :class="[current == '/dashboard' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Dashboard</span>
                        </a>
                    </li>
                    <li v-show="show('admin')" class="nav-main-item">
                        <a @click="routeChange('/controllo-utenti')" :class="[current == '/controllo-utenti' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-user"></i>
                            <span class="nav-main-link-name">Controllo Utenti</span>
                        </a>
                    </li>
                    <li v-show="show('admin|direzione')" class="nav-main-heading">GESTIONE UTENTI</li>
                     <li v-show="show('admin|direzione')" class="nav-main-item">
                        <a @click="routeChange('/gestione-utenti/livello-utenti')" :class="[current == '/gestione-utenti/livello-utenti' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-users"></i>
                            <span class="nav-main-link-name">Livello Utenti</span>
                        </a>
                     </li>
                    <li v-show="show('admin')" class="nav-main-item">
                        <a @click="routeChange('/tabelle-rimborso')" :class="[current == '/tabelle-rimborso' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-level-up-alt"></i>
                            <span class="nav-main-link-name">Tabelle Rimborso</span>
                        </a>
                        <a @click="routeChange('/aggiungi-utente')" :class="[current == '/aggiungi-utente' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-level-up-alt"></i>
                            <span class="nav-main-link-name">Aggiungi Utente</span>
                        </a>
                        <a @click="routeChange('/gestione-utenti/trasferisci-certificato')" :class="[current == '/gestione-utenti/trasferisci-certificato' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-level-up-alt"></i>
                            <span class="nav-main-link-name">Trasferisci Certificato</span>
                        </a>
                        <a @click="routeChange('/gestione-utenti/aggiorna-certificato')" :class="[current == '/gestione-utenti/aggiorna-certificato' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-level-up-alt"></i>
                            <span class="nav-main-link-name">Aggiorna Certificato</span>
                        </a>
                        <a @click="routeChange('/gestione-utenti/cambio-password')" :class="[current == '/gestione-utenti/cambio-password' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-level-up-alt"></i>
                            <span class="nav-main-link-name">Cambio Password</span>
                        </a>
                    </li>
                    <li v-show="show('admin|direzione')" class="nav-main-heading">REPORTISTICA</li>
                    <li v-show="show('admin')" class="nav-main-item">
                        <a @click="routeChange('/certificati/reportistica/registro-presenze')" :class="[current == '/certificati/reportistica/registro-presenze' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-docs"></i>
                            <span class="nav-main-link-name">Registro Presenze</span>
                        </a>
                        <a @click="routeChange('/certificati/reportistica/registro-perito')" :class="[current == '/certificati/reportistica/registro-perito' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-user"></i>
                            <span class="nav-main-link-name">Registro per Perito</span>
                        </a>
                        <a @click="routeChange('/certificati/reportistica/specifiche-da-fatturare')" :class="[current == '/certificati/reportistica/specifiche-da-fatturare' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-drawer"></i>
                            <span class="nav-main-link-name">Specifiche da Fatturare</span>
                        </a>
                        <a @click="routeChange('/mappa-catastale')" :class="[current == '/mappa-catastale' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-map-marked-alt"></i>
                            <span class="nav-main-link-name">Mappa catastale</span>
                        </a>

                    </li>
                    
                    <li v-show="show('direzione')" class="nav-main-item">
                        <a @click="routeChange('/certificati/reportistica/registro-presenze')" :class="[current == '/certificati/reportistica/registro-presenze' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-docs"></i>
                            <span class="nav-main-link-name">Registro Presenze</span>
                        </a>
                        <a @click="routeChange('/certificati/reportistica/registro-perito')" :class="[current == '/certificati/reportistica/registro-perito' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-user"></i>
                            <span class="nav-main-link-name">Registro per Perito</span>
                        </a>
                         <a @click="routeChange('/certificati/reportistica/registro-fatture')" :class="[current == '/certificati/reportistica/registro-fatture' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-drawer"></i>
                            <span class="nav-main-link-name">Registro Fatture</span>
                        </a>
                        <a @click="routeChange('/certificati/reportistica/specifiche-da-fatturare')" :class="[current == '/certificati/reportistica/specifiche-da-fatturare' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-drawer"></i>
                            <span class="nav-main-link-name">Specifiche da Fatturare</span>
                        </a>
                        <a @click="routeChange('/reportistica/report-statistico')" :class="[current == '/reportistica/report-statistico' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa si si-calculator"></i>
                            <span class="nav-main-link-name">Report Statistico</span>
                        </a>
                        <a @click="routeChange('/mappa-catastale')" :class="[current == '/mappa-catastale' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-map-marked-alt"></i>
                            <span class="nav-main-link-name">Mappa catastale</span>
                        </a>
                        <a @click="routeChange('/periti-attivi')" :class="[current == '/periti-attivi' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon fa fa-file-archive"></i>
                            <span class="nav-main-link-name">Schedario Periti Attivi</span>
                        </a>
                    </li>
                    
                    <li v-show="show('direzione')" class="nav-main-heading">ARCHIVIO</li>
                    <li v-show="show('direzione')" class="nav-main-item">
                        <a @click="routeChange('/archivio/esporta-csv')" :class="[current == '/archivio/esporta-csv' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-arrow-down"></i>
                            <span class="nav-main-link-name">Esportazione CSV</span>
                        </a>
                    </li>
                    
                    <li v-show="show('direzione')" class="nav-main-heading">CERTIFICATI</li>
                    <li v-show="show('direzione')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/direzione/report-totale`)" :class="[current == `/certificati/direzione/report-totale` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Report Totale</span>
                        </a>
                    </li>
                    <li v-show="show('direzione')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/direzione/report-direzione`)" :class="[current == `/certificati/direzione/report-direzione` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Report Direzione</span>
                        </a>
                    </li>
                    <li v-show="show('direzione')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/direzione/lista-bollettini`)" :class="[current == `/certificati/direzione/lista-bollettini` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Lista Bollettini</span>
                        </a>
                    </li>
                    <li v-show="show('admin')" class="nav-main-heading">CORREZIONI</li>
                    <li v-show="show('admin')" class="nav-main-item">
                        <a @click="routeChange('/bollettini/annulla')" :class="[current == '/bolletini/annulla' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Annulla Bollettini</span>
                        </a>
                        <a @click="routeChange('/fatture/annulla')" :class="[current == '/fatture/annulla' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Annulla Fatture</span>
                        </a>
                        <a @click="routeChange('/specifiche/annulla')" :class="[current == '/specifiche/annulla' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Annulla Specifiche</span>
                        </a>
                    </li>
                    
                    <li v-show="show('ispettore|perito')" class="nav-main-heading">GESTIONE SPECIFICHE</li>
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/specifica/nuova')" :class="[current == '/specifica/nuova' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Nuova Specifica</span>
                        </a>
                        <a @click="routeChange('/specifica/archivio')" :class="[current == '/specifica/archivio' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Archivio Specifiche</span>
                        </a>
                    </li>

                    <li v-show="show('ispettore')" class="nav-main-heading">PANNELLO ISPETTORE</li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/specifica/da-approvare')" :class="[current == '/specifica/da-approvare' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Specifiche da approvare</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/specifica/approvate')" :class="[current == '/specifica/approvate' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Specifiche approvate</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/periti-impegnati')" :class="[current == '/periti-impegnati' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Periti impegnati</span>
                        </a>
                    </li>

                    <li v-show="show('ispettore|perito')" class="nav-main-heading">FATTURAZIONE</li>
                     <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/fatture/archivio')" :class="[current == '/fatture/archivio' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Archivio Fatture</span>
                        </a>
                     </li>
                     <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/fatture/lista/pre')" :class="[current == '/fatture/lista/pre' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Fino al 30/04/{{annoAttuale}}</span>
                        </a>
                     </li>
                     <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/fatture/lista/post')" :class="[current == '/fatture/lista/post' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Crea Fattura</span>
                        </a>
                     </li>

                     <li v-show="show('ispettore')" class="nav-main-heading">REPORTISTICA</li>
                     <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/reportistica/report-periti-ispettore')" :class="[current == '/reportistica/report-periti-ispettore' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-energy"></i>
                            <span class="nav-main-link-name">Report periti</span>
                        </a>
                     </li>
                </ul>
                 <ul v-if="choise == 'certificati' && role == 'perito'" class="nav-main">
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/scegli-sezione')" :class="[current == '/scegli-sezione' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Cambia Sezione</span>
                        </a>
                    </li>

                    <span v-for="ispettore in ispettori" :key="ispettore.id">
                        <li class="nav-main-heading">GESTIONE CARICO {{ispettore.surname.toUpperCase()}}</li>
                        <li v-show="show('perito')" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/lista`)" :class="[current == `/certificati/${ispettore.id}/lista` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-speedometer"></i>
                                <span class="nav-main-link-name">Lista Certificati</span>
                            </a>
                        </li>
                        <li v-show="show('perito')" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/lista-archiviati`)" :class="[current == `/certificati/${ispettore.id}/lista-archiviati` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-docs"></i>
                                <span class="nav-main-link-name">Lista Certificati Archiviati</span>
                            </a>
                        </li>
                        <li v-show="show('perito:full', ispettore)" class="nav-main-item">
                            <a @click="routeChange(`/gestione-carico/${ispettore.id}/lista-csv`)" :class="[current == `/gestione-carico/${ispettore.id}/lista-csv` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-docs"></i>
                                <span class="nav-main-link-name">Lista Csv</span>
                            </a>
                        </li>
                        <li v-show="show('perito')" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/lista-ricolpita-manuale`)" :class="[current == `/certificati/${ispettore.id}/lista-ricolpita-manuale` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-speedometer"></i>
                                <span class="nav-main-link-name">Report Provvisori</span>
                            </a>
                        </li>
                        <li v-show="show('perito')" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/report-totale`)" :class="[current == `/certificati/${ispettore.id}/report-totale` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-notebook"></i>
                                <span class="nav-main-link-name">Report Totale</span>
                            </a>
                        </li>
                        <li v-show="show('perito:full', ispettore)" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/report-carico-scarico`)" :class="[current == `/certificati/${ispettore.id}/report-carico-scarico` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-notebook"></i>
                                <span class="nav-main-link-name">Report Carico Scarico</span>
                            </a>
                        </li>
                        <li v-show="show('perito')" class="nav-main-item">
                            <a @click="routeChange(`/certificati/${ispettore.id}/report-varieta`)" :class="[current == `/certificati/${ispettore.id}/report-varieta` ? 'active' : '' ,'nav-main-link']">
                                <i class="nav-main-link-icon si si-notebook"></i>
                                <span class="nav-main-link-name">Report Varietà</span>
                            </a>
                        </li>
                    </span>
                    
                 </ul>
                 <ul v-if="choise == 'certificati' && role == 'ispettore'" class="nav-main">
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange('/scegli-sezione')" :class="[current == '/scegli-sezione' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Cambia Sezione</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-heading">GESTIONE UTENTI</li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/gestione-utenti/gestione-periti')" :class="[current == '/gestione-utenti/gestione-periti' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Gestione Periti</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/gestione-utenti/trasferisci-certificato')" :class="[current == '/gestione-utenti/trasferisci-certificato' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-arrow-up"></i>
                            <span class="nav-main-link-name">Trasferisci Certificato</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-heading">GESTIONE CARICO</li>
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/lista`)" :class="[current == `/certificati/${user.id}/lista` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Lista Certificati</span>
                        </a>
                    </li>
                    
                    
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/lista-archiviati`)" :class="[current == `/certificati/${user.id}/lista-archiviati` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-docs"></i>
                            <span class="nav-main-link-name">Lista Certificati Archiviati</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/gestione-carico/lista-bollettini')" :class="[current == '/gestione-carico/lista-bollettini' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-docs"></i>
                            <span class="nav-main-link-name">Lista Bollettini</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange(`/gestione-carico/${user.id}/lista-csv`)" :class="[current == `/gestione-carico/${user.id}/lista-csv` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-docs"></i>
                            <span class="nav-main-link-name">Lista Csv</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-heading">REPORT</li>
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/lista-ricolpita-manuale`)" :class="[current == `/certificati/${user.id}/lista-ricolpita-manuale` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-speedometer"></i>
                            <span class="nav-main-link-name">Report Provvisori</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/report-totale`)" :class="[current == `/certificati/${user.id}/report-totale` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Report Totale</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/report-carico-scarico`)" :class="[current == `/certificati/${user.id}/report-carico-scarico` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Report Carico Scarico</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore|perito')" class="nav-main-item">
                        <a @click="routeChange(`/certificati/${user.id}/report-varieta`)" :class="[current == `/certificati/${user.id}/report-varieta` ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Report Varietà</span>
                        </a>
                    </li>
                    <li v-show="show('ispettore')" class="nav-main-heading">Assistenza</li>
                    <li v-show="show('ispettore')" class="nav-main-item">
                        <a @click="routeChange('/bollettini/annulla')" :class="[current == '/bolletini/annulla' ? 'active' : '' ,'nav-main-link']">
                            <i class="nav-main-link-icon si si-notebook"></i>
                            <span class="nav-main-link-name">Richiesta Annulla Bollettino</span>
                        </a>
                    </li>
                </ul>
                <div class="tutoraial-div" :class="class_tutorial">
                    <a class="font-w600 support_tutorial"  href="https://myara1857.freshdesk.com/support/home" target="_blank" >Tutorial - Supporto</a>
                </div>
            </div>
            <!-- END Side Navigation -->
        </nav>
        <!-- END Sidebar -->
</template>

<script>
export default {

    data(){
        return {
            current: '/',
            annoAttuale:null,
            class_tutorial:''
        }
    },
    watch: {
        '$route' (to) {
            this.current = to.path
            this.removeClass()            
        }
    },
    computed: {
        choise(){
            return this.$store.getters.choise
        },
        role(){
            return this.$store.getters.user ? this.$store.getters.user.role : ''
        },
        user(){
            return this.$store.getters.user
        },
        ispettori(){
            return this.$store.getters.ispettori
        }
    },
    methods: {
        routeChange(name){
            if(this.current == name){
                return
            }
            
            this.$router.push({ path: name })
        },
        show(roles, ispettore = null){
            
            if(!this.$store.getters.user){
                return false
            }

            if(roles == 'all'){
                return true
            }

            const user = this.$store.getters.user
            const _roles = roles.split('|')

            for (let i = 0; i < _roles.length; i++) {
                const element = _roles[i];
                const element_arr = element.split(':')
                const role = element_arr[0]
                let permissions = []

                if(element_arr[1]){
                    permissions = element_arr[1].split(',') 
                }
            
                if(role == user.role){
                    if(permissions.length == 0 && !ispettore){
                        return true
                    }

                    if(permissions.find(perm => perm == ispettore.permission)){
                        return true
                    }
                }
            }
        },
        removeClass(){
             window.$("#sidebar").removeClass('menu');
        }
    },
    mounted(){
        this.annoAttuale = process.env.VUE_APP_YEAR

        if(navigator.userAgentData.mobile) {
            this.class_tutorial = 'tutoraial-div'
            console.log('mobile')
        }else {
            this.class_tutorial = 'tutoraial-div-desktop'
        }
    },
    created(){
        this.current = this.$router.history.current.path
        this.$nextTick(function () {
            window.$("#sidebar-close").on('click',function() {
                window.$("#sidebar").removeClass('menu');
            })
        })
        
    }
}
</script>

<style scoped>
    .nav-main-link {
        cursor: pointer;
    }
    
    @media (min-width: 992px){
        .sidebar-mini.sidebar-o #sidebar:not(:hover) #sidebar-themes-dropdown span {
            opacity: 0;
        }
        
        .sidebar-mini.sidebar-o #sidebar-themes-dropdown span {
            transition: opacity .28s ease-out;
        }

        .sidebar-o #sidebar {
            -webkit-transform: translateX(0) translateY(0) translateZ(0);
            transform: translateX(0) translateY(0) translateZ(0);
        }
    }

    .tutoraial-div {
        height: 70px;
        background-color: #2c343f;
        position: absolute;
        bottom: 0px;
        width: 90%;
        background-color: #2c343f;
        position: absolute;
        bottom: -3vh;
        left: 2.73%;
        text-align: left;
    }

    .tutoraial-div-desktop {
        width: 90%;
        height: 70px;
        background-color: #2c343f;
        position: absolute;
        bottom: -3vh;
        left: 10%;
        text-align: left;
    }

</style>