import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default({
    //check_bollettino_ordine
    lista(search){        
        var _search = utility.appendParameters(search);         
        return api.get(`/v2/bollettino/lista?token=${store.getters.access_token}&${_search}`);
    },
    deleteApprovato(bollettino_id){        
        return api.delete(`/v2/bollettino/approvato/${bollettino_id}?token=${store.getters.access_token}`);
    },
    check_bollettino_ordine(ordine_id){
        return api.post(`/v2/bollettino/check_bollettino_ordine?token=${store.getters.access_token}`, {ordine_id});
    },
    async get_temporary_url(path){
        const response = await api.post(`/v2/bollettino/temporary-public-path?token=${store.getters.access_token}`, 
        {fullpath:path});

        return response.data.public_path.replace(/\\/g, '');
    },
    async sendMail(bollettino) {
        const response = await api.post(`/v2/bollettino/send-email?token=${store.getters.access_token}`, 
            {bollettino:bollettino});

        return response.data;
    }
});