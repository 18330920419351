import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import CompletaProfilo from '../views/CompletaProfilo.vue'
import LivelloUtenti from '../views/GestioneUtenti/LivelloUtenti.vue'
import MappaCatastale from '../views/Admin/MappaCatastale.vue'
import PeritiAttivi from '../views/Admin/Reportistica/SchedarioPeritiAttivi.vue'
import AggiungiUtente from '../views/GestioneUtenti/AggiungiUtente.vue'
import TabelleRimborso from '../views/GestioneUtenti/TabelleRimborso.vue'
import Dashboard from '../views/Dashboard.vue'
import ControlloUtenti from '../views/Admin/ControlloUtenti.vue'
import ScegliSezione from '../views/ScegliSezione.vue'
import RecuperaPassword from '../views/RecuperaPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ConfermaUtente from '../views/ConfermaUtente.vue'
import CreaFatturazione from '../views/CreaFatturazione.vue'
import Profilo from '../views/Profilo.vue'

import NuovaSpecifica from '../views/Specifica/Nuova.vue'
import DaApprovare from '../views/Specifica/DaApprovare.vue'
import Approvate from '../views/Specifica/Approvate.vue'
import ArchivioSpecifica from '../views/Specifica/Archivio.vue'
import PeritiImpegnati from '../views/Ispettore/PeritiImpegnati.vue'
import ReportPeritiIspettore from '../views/Reportistica/ReportPeritiIspettore.vue'

import FattureLista from '../views/Fatture/Lista.vue'
import FattureArchivio from '../views/Fatture/Archivio.vue'

//GESTIONE UTENTI
import GestionePeriti from '../views/GestioneUtenti/GestionePeriti.vue'
import TrasferisciCertificato from '../views/Certificati/TrasferisciCertificato.vue'
import AggiornaCertificato from '../views/Admin/AggiornaCertificato.vue'
import CambioPassword from '../views/Admin/CambioPassword.vue'
import AnnullaSpecifiche from '../views/Admin/Correzioni/AnnullaSpecifiche.vue'
import AnnullaFatture from '../views/Admin/Correzioni/AnnullaFatture.vue'
import AnnullaBollettini from '../views/Admin/Correzioni/AnnullaBollettini.vue'
import ReportStatistico from '../views/Admin/Reportistica/ReportStatistico.vue'

import ListaCertificati from '../views/Certificati/Lista.vue'
import NuovoCertificato from '../views/Certificati/Nuovo.vue'
import ModificaCertificato from '../views/Certificati/Modifica.vue'
import EditBollettino from '../views/Certificati/EditBollettino.vue'
import ListaCertificatiArchiviati from '../views/Certificati/ListaArchiviati.vue'
import ListaBollettini from '../views/Certificati/ListaBollettini.vue'
import ReportCaricoScarico from '../views/Certificati/ReportCaricoScarico.vue'
import ReportVarieta from '../views/Certificati/ReportVarieta.vue'
import ListaCsv from '../views/Certificati/ListaCsv.vue'
import ListaCsvHistories from '../views/Certificati/ListaCsvHistories.vue'

//REPORTISTICA
import Reportistica from '../views/Admin/Reportistica.vue'
import EsportaCSV from '../views/Archivio/EsportaCSV.vue'

// CERTIFICATI
import ReportTotaleCertificati from '../views/Certificati/ReportTotaleCertificati.vue'
import ReportTotaleDirezione from '../views/Certificati/ReportTotaleDirezione.vue'

import Page404 from '../views/Page404.vue'
import store from '../store'
import AuthenticationRepository from '../services/apis/authentication'
import UserRepository from '../services/apis/user'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { 
          guest: true
        }
    },
    {
        path: '/recupera-password',
        name: 'recupera-password',
        component: RecuperaPassword,
        meta: { 
          guest: true
        }
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      meta: { 
        guest: true
      }
    },
    {
      path: '/conferma-utente/:token',
      name: 'conferma-utente',
      component: ConfermaUtente,
      meta: { 
        guest: true
      }
    },
    {
      path: '/completa-profilo',
      name: 'completa-profilo',
      alias:'/conferma-profilo',
      component: CompletaProfilo,
      meta: { 
        requiresAuth: true,
        roles:'all'
      }
    },
    {
      path: '/crea-fatturazione',
      name: 'crea-fatturazione',
      component: CreaFatturazione,
      meta: { 
        requiresAuth: true,
        roles:'all'
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      alias:'/',
      meta: { 
        requiresAuth: true,
        roles:'all'
      }
    },
  {
    path: '/controllo-utenti',
    name: 'controllo-utenti',
    component: ControlloUtenti,
    meta: { 
      requiresAuth: true,
      roles:'admin'
    }
  },  
  {
      path: '/profilo',
      name: 'profilo',
      component: Profilo,
      meta: { 
        requiresAuth: true,
        roles:'all'
      }
  },
  {
      path: '/gestione-utenti/livello-utenti',
      name: 'livello-utenti',
      component: LivelloUtenti,
      meta: { 
        requiresAuth: true,
        roles:'admin|direzione'
      }
  },
  {
      path: '/tabelle-rimborso',
      name: 'tabelle-rimborso',
      component: TabelleRimborso,
      meta: { 
        requiresAuth: true,
        roles:'admin'
      }
  },
  {
      path: '/aggiungi-utente',
      name: 'aggiungi-utente',
      component: AggiungiUtente,
      meta: { 
        requiresAuth: true,
        roles:'admin'
      }
  },
  {
    path: '/specifica/nuova',
    name: 'nuova-specifica',
    component: NuovaSpecifica,
    meta: { 
      requiresAuth: true,
      roles:'ispettore|perito'
    }
  },
  {
    path: '/specifica/archivio',
    name: 'archivio-specifica',
    component: ArchivioSpecifica,
    meta: { 
      requiresAuth: true,
      roles:'ispettore|perito'
    }
  },
  {
    path: '/specifica/da-approvare',
    name: 'specifica-da-approvare',
    component: DaApprovare,
    meta: { 
      requiresAuth: true,
      roles:'ispettore'
    }
  },
  {
    path: '/periti-impegnati',
    name: 'periti-impegnati',
    component: PeritiImpegnati,
    meta: { 
      requiresAuth: true,
      roles:'ispettore'
    }
  },
   {
      path: '/specifica/approvate',
      name: 'specifica-approvate',
      component: Approvate,
      meta: { 
        requiresAuth: true,
        roles:'ispettore'
      }
    },
    {
      path: '/fatture/archivio',
      name: 'fatture-archivio',
      component: FattureArchivio,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/fatture/lista/pre',
      name: 'fatture-lista-pre',
      component: FattureLista,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/fatture/lista/post',
      name: 'fatture-lista-post',
      component: FattureLista,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/specifica/:id/edit',
      name: 'edit-specifica',
      component: NuovaSpecifica,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
        path: '/scegli-sezione',
        name: 'scegli-sezione',
        component: ScegliSezione,
        meta: { 
          requiresAuth: true,
          roles:'ispettore|perito'
        }
    },
    
    
    {
      path: '/certificati/:ispettore_id/lista',
      name: 'lista-certificati',
      component: ListaCertificati,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/lista-ricolpita-manuale',
      name: 'lista-certificati-ricolpita-manuale',
      component: ListaCertificati,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/lista-archiviati',
      name: 'lista-certificati-archiviati',
      component: ListaCertificatiArchiviati,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/gestione-utenti/gestione-periti',
      name: 'gestione-periti',
      component: GestionePeriti,
      meta: { 
        requiresAuth: true,
        roles:'ispettore'
      }
    },
    {
      path: '/gestione-utenti/trasferisci-certificato',
      name: 'trasferisci-certificato',
      component: TrasferisciCertificato,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|admin'
      }
    },
    {
      path: '/gestione-carico/lista-bollettini',
      name: 'lista-bollettini',
      component: ListaBollettini,
      meta: { 
        requiresAuth: true,
        roles:'ispettore'
      }
    },
    {
      path: '/gestione-carico/:ispettore_id/lista-csv',
      name: 'lista-csv',
      component: ListaCsv,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito',
        permission:['full']
      }
    },
    {
      path: '/gestione-carico/lista-csv/:type/:resource_number/histories',
      name: 'lista-csv-histories',
      component: ListaCsvHistories,
      meta: { 
        requiresAuth: true,
        roles:'ispettore'
      }
    },
    {
      path: '/certificati/:ispettore_id/nuovo',
      name: 'nuovo-certificato',
      component: NuovoCertificato,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/modifica/:certificato_id',
      name: 'modifica-certificato',
      component: ModificaCertificato,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/nuovo/bollettino',
      name: 'nuovo-bollettino',
      component: EditBollettino,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/modifica/:certificato_id/bollettino/:bollettino_id',
      name: 'modifica-bollettino',
      component: EditBollettino,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito|direzione'
      }
    },
    {
      path: '/certificati/:ispettore_id/modifica/:certificato_id/denuncia/:denuncia_id/bollettino',
      name: 'denuncia-bollettino',
      component: EditBollettino,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/report-totale',
      name: 'report-totale-certificati',
      component: ReportTotaleCertificati,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito|direzione'
      }
    },
    {
      path: '/certificati/direzione/report-direzione',
      name: 'report-direzione-certificati',
      component: ReportTotaleDirezione,
      meta: { 
        requiresAuth: true,
        roles:'direzione'
      }
    },
    {
      path: '/certificati/direzione/lista-bollettini',
      name: 'direzione-lista-bollettini',
      component: ListaBollettini,
      meta: { 
        requiresAuth: true,
        roles:'direzione'
      }
    },
    {
      path: '/certificati/:ispettore_id/report-carico-scarico',
      name: 'report-carico-scarico',
      component: ReportCaricoScarico,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/certificati/:ispettore_id/report-varieta',
      name: 'report-varieta',
      component: ReportVarieta,
      meta: { 
        requiresAuth: true,
        roles:'ispettore|perito'
      }
    },
    {
      path: '/gestione-utenti/aggiorna-certificato',
      name: 'aggiorna-certificato',
      component: AggiornaCertificato,
      meta: { 
        requiresAuth: true,
        roles:'admin'
      }
    },
    {
      path: '/gestione-utenti/cambio-password',
      name: 'cambio-password',
      component: CambioPassword,
      meta: { 
        requiresAuth: true,
        roles:'admin'
      }
    },
  {
    path: '/certificati/reportistica/:type',
    name: 'registro-presenze',
    component: Reportistica,
    meta: { 
      requiresAuth: true,
      roles:'admin|direzione'
    }
  },
  {
    path: '/reportistica/report-statistico',
    name: 'report-statistico',
    component: ReportStatistico,
    meta: { 
      requiresAuth: true,
      roles:'direzione'
    }
  },
  {
    path: '/reportistica/report-periti-ispettore',
    name: 'report-periti-ispettore',
    component: ReportPeritiIspettore,
    meta: { 
      requiresAuth: true,
      roles:'ispettore'
    }
  },
  {
    path: '/specifiche/annulla',
    name: 'specifiche-annulla',
    component: AnnullaSpecifiche,
    meta: { 
      requiresAuth: true,
      roles:'admin'
    }
  },
  {
    path: '/fatture/annulla',
    name: 'fatture-annulla',
    component: AnnullaFatture,
    meta: { 
      requiresAuth: true,
      roles:'admin'
    }
  },
  {
    path: '/bollettini/annulla',
    name: 'bollettini-annulla',
    component: AnnullaBollettini,
    meta: { 
      requiresAuth: true,
      roles:'admin|ispettore'
    }
  },
  {
    path: '/archivio/esporta-csv',
    name: 'esporta-csv',
    component: EsportaCSV,
    meta: { 
      requiresAuth: true,
      roles:'direzione'
    }
  },
  {
    path: '/mappa-catastale',
    name: 'mappa-catastale',
    component: MappaCatastale,
    meta: { 
      requiresAuth: true,
      roles:'admin|direzione|ispettore|perito'
    }
  },
  {
    path: '/periti-attivi',
    name: 'periti-attivi',
    component: PeritiAttivi,
    meta: { 
      requiresAuth: true,
      roles:'direzione'
    }
  },
    {
      path: '/404',
      name: '404',
      component: Page404,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  
  if(to.matched.some(record => record.meta.requiresAuth)) {

    let token = localStorage.getItem('myara1857_token')

    if (token == null || token == 'null') {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
    
      if(!store.getters.first_open){    

        (async () => {
          store.dispatch('updateAccessTokenState',token);

          let me = await AuthenticationRepository.me().catch(() => {})

          if(me) {
            store.dispatch('updateChoiseState',localStorage.getItem('choise'))
            store.dispatch('updateLogginState', true)
            store.dispatch('updateUserObjState',me.data.user)
            
            if(me.data.user.role != 'perito' && me.data.user.role != 'ispettore'){
              store.dispatch('updateChoiseState','specifiche')
            }

            if(me.data.user.role == "perito"){
              const _resp = await UserRepository.get_ispettori_associati().catch(() => {})
              if(_resp){
                  if('data' in _resp.data){
                    store.dispatch('updateIspettoriObjState', _resp.data.data)  
                  }
                  if('user' in _resp.data){
                    store.dispatch('updateIspettoriObjState', _resp.data.user)  
                  }
              }
            }

            if(!me.data.user.profile_complete){
              if(to.path != '/completa-profilo'){
                Vue.toasted.error("Devi completare il tuo profilo prima di poter accedere.").goAway(3000)  
                next({name: 'completa-profilo'})
              }
              next();
            }else if(to.path == '/reset-password' ){
                next({path: '/dashboard'})
            }else{
              next()
            }
          }

        })()
        next(false)
      }else{
        to.matched.some(record => {
          let roles = record.meta.roles.split('|')
          let user = store.getters.user
          
          if(!user.profile_complete){
            if(to.path != '/completa-profilo'){
              Vue.toasted.error("Devi completare il tuo profilo prima di poter accedere.").goAway(3000)  
              next({name: 'completa-profilo'})
            }
            next()
          }else if(user.profile_complete && to.path == '/reset-password'){
              next({path: '/dashboard'})
          }else{
            
            if(roles.find(role => role == 'all' || user.role == role)){
              
              next();
            }else{
              next({ name: 'dashboard'})
            }

          }
        })
      }
    }
  } else if(to.matched.some(record => record.meta.guest)) {

      if( localStorage.getItem('myara1857_token') == null || 
          localStorage.getItem('myara1857_token') == 'null'){
          
        next()
      } else {
        next({ name: 'dashboard'})
      }
  }else{
    
    if(to.matched.length == 0){
      next({name:"404"})
    }    
    
    next() 
  }
  if(!store.getters.first_open){
    store.dispatch('updateFirstOpenState',true)       
  }

  
})

export default router